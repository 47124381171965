import {
  Center,
  HStack,
  IconButton,
  Image,
  StackProps,
  Text,
} from "@chakra-ui/react";
import { ZUBE_LOGO_URL } from "helpers/constants/url-constants";
import { HiMenuAlt2 } from "react-icons/hi";
import { SessionMenu } from "../SessionMenu";
import { UserCard } from "../UserCard";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { DisplayType } from "../types.d";
import { FaArrowRightLong } from "react-icons/fa6";
import { zubeColors } from "styles/colors";
import { LiaBellSolid } from "react-icons/lia";
import { getHeaderName } from "helpers/data-helpers/string-helpers";
import { useWindowDimensions } from "hooks/layout/dimension-hooks";
import { NavMenu } from "../NavMenu";

interface INavbarProps extends StackProps {
  onDisplayToggle: () => void;
  onTop: boolean;
  displayType: DisplayType;
}

export const Navbar: React.FC<INavbarProps> = (props): JSX.Element => {
  const { onDisplayToggle, displayType, onTop, ...rest } = props;
  const { width } = useWindowDimensions();
  const userData = useSelector(getUser);

  return (
    <HStack h={onTop && width > 1200 ? "100px" : "80px"} {...rest}>
      {width > 1200 ? (
        <Center minW="200px">
          <Image src={ZUBE_LOGO_URL} h="40px" alignSelf="center" />
        </Center>
      ) : null}
      {width > 1200 ? (
        <IconButton
          bg="transparent"
          onClick={onDisplayToggle}
          aria-label="change-display"
          _hover={{ bg: "transparent" }}
        >
          {displayType === "full" ? (
            <HiMenuAlt2 size={30} />
          ) : (
            <FaArrowRightLong color={zubeColors.zubePurple.dark} size={30} />
          )}
        </IconButton>
      ) : (
        <NavMenu>
          <HiMenuAlt2 color="black" size={30} />
        </NavMenu>
      )}
      <Text fontSize={24} fontWeight="bold" minW={width > 1200 ? "40%" : "60%"}>
        {getHeaderName(width < 1200)}
      </Text>
      <IconButton
        bg="transparent"
        aria-label="notifications"
        _hover={{ bg: "transparent" }}
      >
        <LiaBellSolid size={30} />
      </IconButton>
      <SessionMenu mr={3}>
        <UserCard
          user={userData}
          displayType={width < 1200 ? "shrink" : "full"}
        />
      </SessionMenu>
    </HStack>
  );
};

Navbar.defaultProps = {
  w: "100%",
  justifyContent: "space-between",
  pb: 2,
  position: "sticky",
  bg: "white",
  top: 0,
  zIndex: 1,
};
