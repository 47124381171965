import { Modal, ModalContent, ModalProps } from "@chakra-ui/react";
import { useWindowDimensions } from "hooks/layout/dimension-hooks";
import Lottie from "react-lottie-player";

export const LoadingModal: React.FC<ModalProps> = (props): JSX.Element => {
  const { ...rest } = props;

  const { height, width } = useWindowDimensions();

  return (
    <Modal {...rest}>
      <ModalContent
        alignItems="center"
        bg="white"
        justifyContent="center"
        borderRadius={0}
        h={`${height}px`}
        w={`${width}px`}
      >
        <Lottie
          loop
          path="https://lottie.host/aa92ef00-389b-4a02-9f23-ea7aceda85d0/wlxLAqvEWJ.json"
          play
          style={{ width: 150, height: 150 }}
        />
      </ModalContent>
    </Modal>
  );
};

LoadingModal.defaultProps = {
  size: "full",
};
