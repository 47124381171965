import { Grid, GridItem, VStack } from "@chakra-ui/react";
import { PageContainer } from "components/elements";
import { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { Fare } from "./components/Fare";
import { IFare } from "services/resources/firebase/fare/types.d";
import { fareResources } from "services/resources/firebase/fare";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { isNil } from "lodash/fp";
import { useWindowDimensions } from "hooks/layout/dimension-hooks";

export const Fares: React.FC = (): JSX.Element => {
  const { token } = useSelector(getUser);
  const { width } = useWindowDimensions();
  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);
  const [fare, setFare] = useState<IFare>({} as IFare);

  const defaultMapSettings = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  const handleOnFaresGet = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const { data } = await fareResources.getByVehicleType(
        "standard",
        token as string
      );
      if (!isNil(data)) {
        setFare(data);
      }
    } catch (e) {
      console.log("Error-handleOnFareGet: ", e);
    }
    setLoadingModalVisible(false);
  };

  useEffect(() => {
    handleOnFaresGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer loadingModalVisible={loadingModalVisible}>
      <VStack
        h="100%"
        alignItems="center"
        justifyContent="center"
        overflowY="scroll"
      >
        <Grid
          templateColumns={`repeat(${width < 1200 ? 1 : 2}, 1fr)`}
          gap={3}
          w="90%"
          h="90%"
        >
          <GridItem>
            <Fare fare={fare} mt={width < 1200 ? 5 : undefined} />
          </GridItem>
          <GridItem w="100%" h="50%">
            <GoogleMapReact
              bootstrapURLKeys={{ key: "" }}
              defaultCenter={defaultMapSettings.center}
              defaultZoom={defaultMapSettings.zoom}
            ></GoogleMapReact>
          </GridItem>
        </Grid>
      </VStack>
    </PageContainer>
  );
};
