import { Grid, GridItem, VStack } from "@chakra-ui/react";
import { PageContainer } from "components/elements";
import { useEffect, useState } from "react";
import { DriverRequestsStats } from "./components/DriverRequestsStats";
import { JourneysStats } from "./components/JourneysStats";
import { FinanceStats } from "./components/FinanceStats";
import { driverRequestResources } from "services/resources/firebase/driver-request/driver-request";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { isNil } from "lodash/fp";
import {
  IDriverRequest,
  VerificationStatusEnum,
} from "services/resources/firebase/driver-request/types.d";
import { useWindowDimensions } from "hooks/layout/dimension-hooks";

export const Home: React.FC = (): JSX.Element => {
  const { token } = useSelector(getUser);
  const { width } = useWindowDimensions();
  const [requests, setRequests] = useState<IDriverRequest[]>([]);
  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);
  const pendingRequestsAmount = requests.filter(
    (req) => req.status === VerificationStatusEnum.PENDING
  ).length;
  const acceptedRequestsAmount = requests.filter(
    (req) => req.status === VerificationStatusEnum.APPROVED
  ).length;
  const appointmentRequestsAmount = requests.filter(
    (req) => req.status === VerificationStatusEnum.APPROVED
  ).length;

  const retrieveDriverRequests = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const { data: requestsData } = await driverRequestResources.getAll(
        token as string
      );
      if (!isNil(requestsData)) {
        setRequests(requestsData);
      }
    } catch (e) {
      console.log("Error-retrieveDriverRequests: ", e);
      window.alert("Ocurrió un error inesperado.");
    } finally {
      setLoadingModalVisible(false);
    }
  };

  useEffect(() => {
    retrieveDriverRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer loadingModalVisible={loadingModalVisible}>
      <VStack
        h={width < 1200 ? undefined : "100%"}
        alignItems="center"
        justifyContent="center"
        overflow="auto"
      >
        <Grid
          templateColumns={width < 1200 ? "repeat(1, 1fr)" : "repeat(2, 1fr)"}
          gap={3}
          my={4}
          w="90%"
        >
          <GridItem>
            <DriverRequestsStats
              pendingRequests={pendingRequestsAmount}
              acceptedRequests={acceptedRequestsAmount}
              requestsWithAppointments={appointmentRequestsAmount}
            />
          </GridItem>
          <GridItem>
            <JourneysStats
              allJourneysCount={10}
              cancelled={2}
              cancelledBecauseOfLongWait={2}
              finished={6}
            />
          </GridItem>
          <GridItem>
            <FinanceStats cardIncomes={0} cashIncomes={0} loses={0} />
          </GridItem>
        </Grid>
      </VStack>
    </PageContainer>
  );
};
