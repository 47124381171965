import { Box, BoxProps } from "@chakra-ui/react";

interface IPressableProps extends BoxProps {
  onClick?: () => void;
}

export const Pressable: React.FC<IPressableProps> = (props): JSX.Element => {
  const { onClick = () => {}, ...rest } = props;
  return <Box onClick={onClick} {...rest} />;
};

Pressable.defaultProps = {
  _hover: { cursor: "pointer" },
};
