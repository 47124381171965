import { HStack } from "@chakra-ui/react";
import { DashboardPanel, NumericBadge } from "components/elements";
import { formatMoneyAmount } from "helpers/data-helpers/string-helpers";
import { FaCoins, FaCreditCard, FaTrashAlt } from "react-icons/fa";

interface IFinanceStatsProps {
  cashIncomes: number;
  cardIncomes: number;
  loses: number;
}

export const FinanceStats: React.FC<IFinanceStatsProps> = (
  props
): JSX.Element => {
  const { cardIncomes, cashIncomes, loses } = props;
  return (
    <DashboardPanel label="Finanzas">
      <HStack justifyContent="space-evenly" w="100%">
        <NumericBadge
          icon={<FaCoins size={30} />}
          amount={`$${formatMoneyAmount(cashIncomes)}`}
          label="Viajes en efectivo"
        />
        <NumericBadge
          icon={<FaCreditCard size={30} />}
          amount={`$${formatMoneyAmount(cardIncomes)}`}
          label="Viajes con tarjeta"
        />
        <NumericBadge
          icon={<FaTrashAlt size={30} />}
          amount={`$${formatMoneyAmount(loses)}`}
          label="Viajes perdidos"
        />
      </HStack>
    </DashboardPanel>
  );
};
