import React from "react";

import { InputField } from "../InputField";
import { IInputFieldProps } from "../types.d";

export const EmailField: React.FC<IInputFieldProps> = (props) => {
  const {
    label = "Email",
    error = "",
    dataType = "email",
    onChangeText,
    ...rest
  } = props;
  return (
    <InputField
      dataType={dataType}
      error={error}
      label={label}
      onChangeText={onChangeText}
      {...rest}
    />
  );
};
