import { HStack } from "@chakra-ui/react";
import { DashboardPanel, NumericBadge } from "components/elements";
import { GiSandsOfTime } from "react-icons/gi";
import { FaCalendarDay, FaUserCheck } from "react-icons/fa";

interface IDriverRequestsStatsProps {
  pendingRequests: number;
  requestsWithAppointments: number;
  acceptedRequests: number;
}

export const DriverRequestsStats: React.FC<IDriverRequestsStatsProps> = (
  props
): JSX.Element => {
  const { pendingRequests, requestsWithAppointments, acceptedRequests } = props;
  return (
    <DashboardPanel label="Solicitudes">
      <HStack justifyContent="space-evenly" w="100%">
        <NumericBadge
          icon={<GiSandsOfTime size={30} />}
          amount={pendingRequests.toString()}
          label="Solicitudes pendientes"
        />
        <NumericBadge
          icon={<FaCalendarDay size={28} />}
          amount={requestsWithAppointments.toString()}
          label="Solicitudes con cita"
        />
        <NumericBadge
          icon={<FaUserCheck size={30} />}
          amount={acceptedRequests.toString()}
          label="Aceptados"
        />
      </HStack>
    </DashboardPanel>
  );
};
