import {
  Checkbox,
  CheckboxGroup,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { IPropsWithTable } from "../types.d";

export const ColumnsToggler: React.FC<IPropsWithTable> = (
  props
): JSX.Element => {
  const { table } = props;

  return (
    <VStack alignSelf="flex-end" alignItems="flex-start" spacing={0}>
      <Text>Ocultar / Mostrar Columnas</Text>
      <CheckboxGroup>
        <HStack>
          <Checkbox
            key="toggleAll"
            isChecked={table.getIsAllColumnsVisible()}
            onChange={table.getToggleAllColumnsVisibilityHandler()}
          />
          <Text>Mostrar todos</Text>
        </HStack>
        {table.getAllLeafColumns().map((column) => {
          return (
            <HStack>
              <Checkbox
                key={column.id}
                isChecked={column.getIsVisible()}
                onChange={column.getToggleVisibilityHandler()}
              />
              <Text>{column.columnDef.header as string}</Text>
            </HStack>
          );
        })}
      </CheckboxGroup>
    </VStack>
  );
};
