import {
  Box,
  IconButton,
  Table,
  TableContainer,
  TableContainerProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  flexRender,
  Table as TableProps,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { ColumnsToggler } from "./components/ColumnsToggler";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { zubeColors } from "styles/colors";
import { Pagination } from "./components/Pagination";

interface IDashboardTableProps<DataType extends object>
  extends TableContainerProps {
  name?: string;
  columns: ColumnDef<object>[];
  data: DataType[];
  showColumnsToggler?: boolean;
  pageSizeNumber?: number;
  defaultHiddenColumns?: number[];
}

export const DashboardTable = <DataType extends object>(
  props: IDashboardTableProps<DataType>
) => {
  const {
    name,
    columns,
    data,
    showColumnsToggler = true,
    pageSizeNumber = 10,
    defaultHiddenColumns = [],
    ...rest
  } = props;

  const [showToggler, setShowToggler] = useState<boolean>(false);
  const [columnVisibility, setColumnVisibility] = useState({});

  const table: TableProps<object> = useReactTable({
    data: data,
    columns,
    state: {
      columnVisibility,
    },
    initialState: {
      pagination: {
        pageSize: pageSizeNumber,
      },
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    // Check all columns and hide the ones passed in the default
    // hidden array
    table.getAllColumns().forEach((column, index) => {
      if (defaultHiddenColumns.includes(index)) {
        column.toggleVisibility();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table]);

  return (
    <TableContainer {...rest}>
      <Box textAlign="right" w="100%" pr={5}>
        {showColumnsToggler ? (
          <IconButton
            onClick={() => setShowToggler(!showToggler)}
            icon={!showToggler ? <FaEye size={22} /> : <FaEyeSlash size={22} />}
            aria-label="show-hide-columns"
            bg="transparent"
            color={zubeColors.zubeGray.dark}
          />
        ) : null}
        {showToggler ? <ColumnsToggler table={table} /> : null}
      </Box>
      <Table variant="simple">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th color="gray" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Pagination table={table} />
    </TableContainer>
  );
};

TableContainer.defaultProps = {
  overflowY: "scroll",
};
