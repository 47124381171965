import { Center, StackProps, Text, VStack } from "@chakra-ui/react";
import { zubeColors } from "styles/colors";

interface INumericBadgeProps extends StackProps {
  icon: JSX.Element;
  amount: string;
  label: string;
}

export const NumericBadge: React.FC<INumericBadgeProps> = (props) => {
  const { icon, amount, label, ...rest } = props;
  return (
    <VStack {...rest}>
      <Center
        bg={zubeColors.zubePurple.lighter}
        color={zubeColors.zubePurple.dark}
        rounded="full"
        boxSize="55px"
      >
        {icon}
      </Center>
      <Text fontSize={24} fontWeight="bold" textAlign="center">
        {amount}
      </Text>
      <Text fontSize={12} textAlign="center">
        {label}
      </Text>
    </VStack>
  );
};

NumericBadge.defaultProps = {
  alignItems: "center",
  justifyContent: "center",
};
