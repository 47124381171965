import { Table as TableProps } from "@tanstack/react-table";
import { ArrowButton } from "./ArrowButton";
import { Center, HStack, Input, Text } from "@chakra-ui/react";
import { ShowDropdown } from "./ShowDropdown";

interface IPaginationProps {
  table: TableProps<object>;
}

export const Pagination: React.FC<IPaginationProps> = (props): JSX.Element => {
  const { table } = props;

  return (
    <Center>
      <ArrowButton type="doubleLeft" table={table} />
      <ArrowButton type="singleLeft" table={table} />
      <ArrowButton type="singleRight" table={table} />
      <ArrowButton type="doubleRight" table={table} />
      <HStack px={1}>
        <Text>Página</Text>
        <Text fontWeight="bold">
          {table.getState().pagination.pageIndex + 1} de {table.getPageCount()}
        </Text>
      </HStack>
      <HStack>
        <Text>| Ir a la página:</Text>
        <Input
          type="number"
          defaultValue={table.getState().pagination.pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            table.setPageIndex(page);
          }}
        />
      </HStack>
      <ShowDropdown table={table} />
    </Center>
  );
};
