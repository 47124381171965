export const handleFirebaseError = (error: string): string => {
  let outputErrorMessage = '';
  switch (error) {
    case 'auth/email-already-in-use':
      outputErrorMessage = 'Este correo ya está en uso';
      break;
    case 'auth/code-expired':
      outputErrorMessage = 'Tu código caducó.';
      break;
    case 'auth/too-many-requests':
      outputErrorMessage = 'Intentos excedidos, prueba en unos minutos.';
      break;
    case 'auth/wrong-password':
      outputErrorMessage = 'Contraseña Incorrecta.';
      break;
    case 'auth/user-not-found':
      outputErrorMessage = 'Usuario no encontrado.';
      break;
    case 'auth/invalid-credential':
      outputErrorMessage =
        'Esa combinación de usuario y contraseña no funciona.';
      break;
    case 'auth/invalid-login-credentials':
      outputErrorMessage =
        'Esa combinación de usuario y contraseña no funciona.';
      break;
    case 'auth/weak-password':
      outputErrorMessage = 'Contraseña muy débil.';
      break;
    case 'auth/invalid-email':
      outputErrorMessage = 'Correo no válido.';
      break;
    default:
      break;
  }
  return outputErrorMessage;
};
