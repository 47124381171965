import { firebaseInstance } from "services/resources/firebase";
import { type IAxiosResponse } from "services/types.d";

import {
  IDoubledSidedPicture,
  IVerifyingDocument,
  IVerifyingVehicleForm,
  VerificationStatusEnum,
  type IDriverRequest,
} from "./types.d";

const DRIVER_REQUESTS_API_URL = "/driversRequestsAPI";

export const driverRequestResources = {
  delete: (
    requestId: string,
    token: string
  ): Promise<IAxiosResponse<undefined>> =>
    firebaseInstance.delete(`${DRIVER_REQUESTS_API_URL}/`, {
      headers: { Authorization: token },
      params: { id: requestId },
    }),

  getAll: (token: string): Promise<IAxiosResponse<IDriverRequest[]>> =>
    firebaseInstance.get(`${DRIVER_REQUESTS_API_URL}/`, {
      headers: { Authorization: token },
    }),

  getById: (
    requestId: string,
    token: string
  ): Promise<IAxiosResponse<IDriverRequest>> =>
    firebaseInstance.get(`${DRIVER_REQUESTS_API_URL}/by-id`, {
      headers: { Authorization: token },
      params: { id: requestId },
    }),

  patchRequestStatus: (
    newStatus: VerificationStatusEnum,
    requestId: string,
    token: string
  ): Promise<IAxiosResponse<string>> =>
    firebaseInstance.patch(
      `${DRIVER_REQUESTS_API_URL}/status`,
      {},
      {
        headers: { Authorization: token },
        params: { id: requestId, status: newStatus },
      }
    ),

  patchControlCard: (
    payload: IVerifyingDocument<string>,
    requestId: string,
    token: string
  ): Promise<IAxiosResponse<string>> =>
    firebaseInstance.patch(
      `${DRIVER_REQUESTS_API_URL}/control-card`,
      { controlCard: payload },
      {
        headers: { Authorization: token },
        params: { id: requestId },
      }
    ),

  patchIdCard: (
    payload: IVerifyingDocument<IDoubledSidedPicture>,
    requestId: string,
    token: string
  ): Promise<IAxiosResponse<string>> =>
    firebaseInstance.patch(
      `${DRIVER_REQUESTS_API_URL}/id-card`,
      { idCard: payload },
      {
        headers: { Authorization: token },
        params: { id: requestId },
      }
    ),

  patchDriverLicense: (
    payload: IVerifyingDocument<IDoubledSidedPicture>,
    requestId: string,
    token: string
  ): Promise<IAxiosResponse<string>> =>
    firebaseInstance.patch(
      `${DRIVER_REQUESTS_API_URL}/driver-license`,
      { driverLicense: payload },
      {
        headers: { Authorization: token },
        params: { id: requestId },
      }
    ),

  patchInsurance: (
    payload: IVerifyingDocument<string>,
    requestId: string,
    token: string
  ): Promise<IAxiosResponse<string>> =>
    firebaseInstance.patch(
      `${DRIVER_REQUESTS_API_URL}/insurance`,
      { insurance: payload },
      {
        headers: { Authorization: token },
        params: { id: requestId },
      }
    ),

  patchCirculationCard: (
    payload: IVerifyingDocument<string>,
    requestId: string,
    token: string
  ): Promise<IAxiosResponse<string>> =>
    firebaseInstance.patch(
      `${DRIVER_REQUESTS_API_URL}/circulation-card`,
      { circulationCard: payload },
      {
        headers: { Authorization: token },
        params: { id: requestId },
      }
    ),

  patchVehicleInfo: (
    payload: IVerifyingVehicleForm<string>,
    requestId: string,
    token: string
  ): Promise<IAxiosResponse<string>> =>
    firebaseInstance.patch(
      `${DRIVER_REQUESTS_API_URL}/vehicle-info`,
      { vehicleInfo: payload },
      {
        headers: { Authorization: token },
        params: { id: requestId },
      }
    ),

  patchGlobalStatus: (
    newStatus: VerificationStatusEnum,
    requestId: string,
    token: string
  ): Promise<IAxiosResponse<string>> =>
    firebaseInstance.patch(
      `${DRIVER_REQUESTS_API_URL}/global-status`,
      { status: newStatus },
      {
        headers: { Authorization: token },
        params: { id: requestId },
      }
    ),
};
