import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { isEmpty } from "lodash/fp";
import { type RootState } from "redux-service/store";
import { type IUser } from "services/resources/firebase/user/types.d";

import { type IUserState } from "./types.d";

const initialState: IUserState = {
  ...({} as IUser),
  logged: false,
  token: "",
};

export const userSlice = createSlice({
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  name: "user",
  reducers: {
    setAvailable: (state, action: PayloadAction<boolean>) => {
      state.available = action.payload;
    },
    setLogged: (state, action: PayloadAction<boolean>) => {
      state.logged = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      if (!isEmpty(action.payload)) {
        state.token = action.payload;
      }
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setUserData: (state, action: PayloadAction<IUserState>) => {
      if (!isEmpty(action.payload)) {
        // Map each payload field to each state field
        Object.entries(action.payload).forEach(([key, value]) => {
          (state as Record<string, unknown>)[key] = value;
        });
      }
    },
  },
});

export const { setLogged, setToken, setUserData, setAvailable } =
  userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getUser = (state: RootState): IUserState => state.user;

export const userReducer = userSlice.reducer;
