import { emailRegex } from 'helpers/data-helpers/regex-helpers';
import { type IBaseAuthentication } from 'services/resources/firebase/auth/types.d';
import * as Yup from 'yup';

export const LoginFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegex, 'Debes usar un correo válido.')
    .required('Este campo es requerido.'),
});

export const INITIAL_VALUES: Pick<IBaseAuthentication, 'email'> = {
  email: '',
};
