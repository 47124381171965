import {
  Box,
  BoxProps,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { zubeColors } from "styles/colors";
import { FiUser } from "react-icons/fi";
import { RxExit } from "react-icons/rx";
import { setUserData } from "redux-service/slices";
import { IUserState } from "redux-service/slices/user/types.d";
import { authResources } from "services/resources/firebase/auth";
import { useDispatch } from "react-redux";
import { deleteSession } from "helpers/session-helpers/session-storage";

interface ISessionMenuProps extends BoxProps {
  onTop?: boolean;
}

export const SessionMenu: React.FC<ISessionMenuProps> = (props) => {
  const { onTop, children, ...rest } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const handleOnSignOut = async (): Promise<void> => {
    try {
      dispatch(
        setUserData({ ...({} as IUserState), img: undefined, logged: false })
      );
      deleteSession();
      await authResources.signOut();
      navigate("/login");
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Error-handleOnSignOut", e);
      toast({
        title: "Error de autenticación",
        description: "Hubo un problema al cerrar la sesión.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <Box {...rest}>
      <Menu>
        <MenuButton
          _active={{ color: zubeColors.zubeGray.dark }}
          _focus={{}}
          _hover={{ color: zubeColors.adminRed }}
          as={Button}
          bg="transparent"
          fontSize={onTop ? 30 : 18}
          my={5}
        >
          {children}
        </MenuButton>
        <MenuList bg="white">
          <MenuItem
            bg="white"
            color="black"
            onClick={() => navigate("/dashboard")}
            icon={<FiUser size={22} color="blue" />}
          >
            Editar Perfil
          </MenuItem>
          <MenuDivider bg="black" />
          <MenuItem
            icon={<RxExit size={22} color="red" />}
            bg="white"
            color="black"
            onClick={() => handleOnSignOut()}
          >
            Salir
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};
