import { HStack, StackProps, Text, VStack } from "@chakra-ui/react";
import { Progress } from "@chakra-ui/react";

interface IProgressBadgeProps extends StackProps {
  total: number;
  sum: number;
  sumLabel: string;
}

export const ProgressBadge: React.FC<IProgressBadgeProps> = (props) => {
  const { total, sum, sumLabel, ...rest } = props;

  const percentage = (sum * 100) / total;

  return (
    <VStack {...rest}>
      <Progress
        colorScheme="orange"
        value={percentage}
        w="100%"
        rounded="full"
      />
      <HStack w="100%" justifyContent="space-between">
        <Text fontSize={12}>{`${sum} ${sumLabel}`}</Text>
        <Text fontSize={12}>{`${percentage}%`}</Text>
      </HStack>
    </VStack>
  );
};

ProgressBadge.defaultProps = {
  w: "50%",
};
