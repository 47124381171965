import { Link, HStack, StackProps, IconButton } from "@chakra-ui/react";
import { DisplayType } from "components/elements/types.d";
import { Link as RichLink } from "react-router-dom";
import { zubeColors } from "styles/colors";
import { GoDotFill } from "react-icons/go";

interface IPanelLinkProps extends StackProps {
  displayType: DisplayType;
  to: string;
  icon?: JSX.Element;
}

export const PanelLink: React.FC<IPanelLinkProps> = (props) => {
  const {
    children,
    displayType,
    to,
    icon = <GoDotFill color={zubeColors.zubeGray.dark} />,
    ...rest
  } = props;
  return (
    <HStack
      justifyContent={displayType === "full" ? "left" : "center"}
      pl={displayType === "full" ? "15px" : undefined}
      _hover={{ cursor: "pointer" }}
      {...rest}
    >
      <IconButton
        bg="transparent"
        aria-label="menu-button"
        _hover={
          displayType !== "full"
            ? {
                bg: zubeColors.zubePurple.lighter,
                color: zubeColors.zubePurple.dark,
              }
            : {}
        }
        color={zubeColors.zubeGray.dark}
        rounded="full"
        boxSize='50px'
      >
        {icon}
      </IconButton>
      {displayType === "full" ? (
        <Link
          as={RichLink}
          fontSize={18}
          color={zubeColors.zubeGray.dark}
          py={4}
          to={to}
          _hover={{
            textDecoration: "none",
            textColor: zubeColors.zubePurple.dark,
          }}
          pl={3}
        >
          {children}
        </Link>
      ) : null}
    </HStack>
  );
};

PanelLink.defaultProps = {
  w: "100%",
  alignItems: "center",
};
