import { StackProps, Text, VStack } from "@chakra-ui/react";

interface IFareItemProps extends StackProps {
  header: string;
  input: JSX.Element;
  description: string;
}

export const FareItem: React.FC<IFareItemProps> = (props) => {
  const { header, input, description, ...rest } = props;
  return (
    <VStack {...rest}>
      <Text fontWeight="bold">{header}</Text>
      {input}
      <Text fontSize={12} color="gray">
        {description}
      </Text>
    </VStack>
  );
};

FareItem.defaultProps = {
  alignItems: "flex-start",
};
