const prodEnvironment: boolean = process.env.REACT_APP_ENVIRONMENT === "prod";

export const appConfig = {
  apiKey: prodEnvironment
    ? process.env.REACT_APP_API_KEY_PROD
    : process.env.REACT_APP_API_KEY_DEV,
  authDomain: prodEnvironment
    ? process.env.REACT_APP_API_KEY_PROD
    : process.env.REACT_APP_API_KEY_DEV,
  projectId: prodEnvironment
    ? process.env.REACT_APP_PROJECT_ID_DEV_PROD
    : process.env.REACT_APP_PROJECT_ID_DEV,
  storageBucket: prodEnvironment
    ? process.env.REACT_APP_STORAGE_BUCKET_PROD
    : process.env.REACT_APP_STORAGE_BUCKET_DEV,
};
