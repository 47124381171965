import { StackProps, VStack } from "@chakra-ui/react";
import { PanelLink } from "./components/PanelLink";
import { UserCard } from "../UserCard";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { DisplayType } from "../types.d";
import { SessionMenu } from "../SessionMenu";
import { useState } from "react";
import { BsSpeedometer } from "react-icons/bs";
import { FaClipboard, FaRoad } from "react-icons/fa";
import { BiSolidUserBadge } from "react-icons/bi";
import { FaUsers } from "react-icons/fa6";
import { MdAttachMoney } from "react-icons/md";

interface IAdminPanelProps extends StackProps {
  displayType: DisplayType;
}

export const AdminPanel: React.FC<IAdminPanelProps> = (props) => {
  const { displayType, ...rest } = props;

  const userData = useSelector(getUser);

  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <VStack spacing={displayType === "full" ? undefined : 7} {...rest}>
      <SessionMenu
        w="100%"
        onPointerEnter={() => setIsHovered(true)}
        onPointerLeave={() => setIsHovered(false)}
        _hover={{ cursor: "pointer" }}
      >
        <UserCard
          user={userData}
          showAdminRole
          displayType={displayType}
          showRightIcon
          isHovered={isHovered}
          flex={1}
        />
      </SessionMenu>
      <PanelLink
        icon={<BsSpeedometer size={24} />}
        to="/dashboard"
        displayType={displayType}
      >
        Dashboard
      </PanelLink>
      <PanelLink
        icon={<FaClipboard size={24} />}
        to="/requests"
        displayType={displayType}
      >
        Solicitudes
      </PanelLink>
      <PanelLink
        icon={<BiSolidUserBadge size={24} />}
        to="/drivers"
        displayType={displayType}
      >
        Conductores
      </PanelLink>
      <PanelLink icon={<FaRoad size={24} />} to="" displayType={displayType}>
        Viajes
      </PanelLink>
      <PanelLink
        icon={<FaUsers size={24} />}
        to="/users"
        displayType={displayType}
      >
        Usuarios
      </PanelLink>
      <PanelLink
        icon={<MdAttachMoney size={24} />}
        to="/fares"
        displayType={displayType}
      >
        Tarifas
      </PanelLink>
    </VStack>
  );
};
