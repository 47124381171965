import { initializeApp } from 'firebase/app';
import {
  type ApplicationVerifier,
  type AuthCredential,
  createUserWithEmailAndPassword,
  initializeAuth,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  signOut,
} from 'firebase/auth';
import { type IAxiosResponse } from 'services/types.d';

import { appConfig } from '../firebase-service';
import { firebaseInstance } from '../instance';
import { type IUserSingupPayload } from '../user/types.d';
import { type IBaseAuthentication, type IOtpResponse } from './types.d';

export const auth = initializeAuth(initializeApp(appConfig));

const AUTHENTICATION_API_URL = '/authenticationAPI';

export const authResources = {
  anonymouslyLogIn: () => signInAnonymously(auth),

  generateCustomToken: (
    storedUid: string,
    token: string,
  ): Promise<IAxiosResponse<{ token: string }>> =>
    firebaseInstance.post(
      `${AUTHENTICATION_API_URL}/custom`,
      {},
      { headers: { Authorization: token }, params: { uid: storedUid } },
    ),

  linkPhoneNumberToUser: (
    uid: string,
    phoneNumber: string,
    token: string,
  ): Promise<IAxiosResponse<{ customToken: string }>> =>
    firebaseInstance.patch(
      `${AUTHENTICATION_API_URL}/user/phone-number`,
      { phoneNumber },
      { headers: { Authorization: token }, params: { uid } },
    ),

  logInWithCustomToken: (customToken: string) =>
    signInWithCustomToken(auth, customToken),

  logInWithEmailAndPassword: (
    signUpPayload: Partial<IUserSingupPayload> | IBaseAuthentication,
  ) =>
    signInWithEmailAndPassword(
      auth,
      signUpPayload.email as string,
      signUpPayload.password as string,
    ),

  logInWithEmailAndPasswordCredential: (credential: AuthCredential) =>
    signInWithCredential(auth, credential),

  logInWithPhoneNumber: (phoneNumber: string, verifier: ApplicationVerifier) =>
    signInWithPhoneNumber(auth, `+52${phoneNumber}`, verifier),

  resetPassword: (email: string) => sendPasswordResetEmail(auth, email),

  sendOtpSMS: (
    phoneNumber: string,
    token: string,
  ): Promise<IAxiosResponse<IOtpResponse>> =>
    firebaseInstance.post(
      `${AUTHENTICATION_API_URL}/sms/otp`,
      {},
      { headers: { Authorization: token }, params: { phoneNumber } },
    ),

  signOut: () => signOut(auth),

  signUpWithEmail: (
    uid: string,
    email: string,
    token: string,
  ): Promise<IAxiosResponse<{ customToken: string }>> =>
    firebaseInstance.patch(
      `${AUTHENTICATION_API_URL}/user/email`,
      { email },
      { headers: { Authorization: token }, params: { uid } },
    ),

  signUpWithEmailAndPassword: (signUpPayload: Partial<IUserSingupPayload>) =>
    createUserWithEmailAndPassword(
      auth,
      signUpPayload.email as string,
      signUpPayload.password as string,
    ),

  verifyOtp: (
    phoneNumber: string,
    otpCode: string,
    token: string,
  ): Promise<IAxiosResponse<IOtpResponse>> =>
    firebaseInstance.post(
      `${AUTHENTICATION_API_URL}/otp/verify`,
      {},
      { headers: { Authorization: token }, params: { otpCode, phoneNumber } },
    ),
};
