import { ReactNode } from "react";
import { Button } from "@chakra-ui/react";
import { IPropsWithTable } from "../types.d";

interface IArrowButtonProps extends IPropsWithTable {
  children?: ReactNode;
  type: "singleLeft" | "singleRight" | "doubleLeft" | "doubleRight";
}

export const ArrowButton: React.FC<IArrowButtonProps> = (
  props
): JSX.Element => {
  const { children, type, table } = props;

  let arrow = "";
  let event;
  let isVisible = false;

  switch (type) {
    case "singleLeft":
      arrow = "<";
      event = () => table.previousPage();
      isVisible = !table.getCanPreviousPage();
      break;
    case "singleRight":
      arrow = ">";
      event = () => table.nextPage();
      isVisible = !table.getCanNextPage();
      break;
    case "doubleLeft":
      arrow = "<<";
      event = () => table.setPageIndex(0);
      isVisible = !table.getCanPreviousPage();
      break;
    case "doubleRight":
      arrow = ">>";
      event = () => table.setPageIndex(table.getPageCount() - 1);
      isVisible = !table.getCanNextPage();
      break;
    default:
      break;
  }

  return (
    <Button onClick={event} isDisabled={isVisible}>
      {children ? children : arrow}
    </Button>
  );
};
