import { Box, HStack, StackProps, VStack } from "@chakra-ui/react";
import { useWindowDimensions } from "hooks/layout/dimension-hooks";
import { Footer } from "../Footer";
import { Navbar } from "../Navbar";
import { AdminPanel } from "../AdminPanel";
import { useEffect, useState } from "react";
import { DisplayType } from "../types.d";
import { zubeColors } from "styles/colors";
import { LoadingModal } from "../LoadingModal";

interface IPageContainerProps extends StackProps {
  showNavbar?: boolean;
  showFooter?: boolean;
  useLeftPanel?: boolean;
  /**
   * Flag that indicates weather to show a loading modal or not.
   */
  loadingModalVisible?: boolean;
}

export const PageContainer: React.FC<IPageContainerProps> = (
  props
): JSX.Element => {
  const {
    children,
    showFooter = true,
    showNavbar = true,
    useLeftPanel = true,
    loadingModalVisible = false,
    ...rest
  } = props;
  const { height, width } = useWindowDimensions();
  const [displayType, setDisplayType] = useState<DisplayType>("full");
  const [onTop, setOnTop] = useState<boolean>(true);

  const toggleDisplay = (): void => {
    setDisplayType(displayType === "full" ? "shrink" : "full");
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY !== 0) {
        setOnTop(false);
      } else {
        setOnTop(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <VStack minHeight={height} {...rest}>
      <LoadingModal
        isOpen={loadingModalVisible}
        onClose={() => {}}
        children={<></>}
      />
      {showNavbar ? (
        <Navbar
          displayType={displayType}
          onTop={onTop}
          onDisplayToggle={toggleDisplay}
        />
      ) : null}
      {useLeftPanel ? (
        width < 1200 ? (
          <Box
            w="100%"
            h="100%"
            alignItems="center"
            justifyContent="center"
            bg={zubeColors.adminGray}
          >
            {children}
          </Box>
        ) : (
          <HStack
            w="100%"
            h="100%"
            height={`${height - 60 - (onTop ? 100 : 80)}px`}
            spacing={0}
          >
            <AdminPanel
              displayType={displayType}
              w={displayType === "full" ? "15%" : "5%"}
              h="100%"
              bg="white"
            />
            <Box
              w={displayType === "full" ? "85%" : "95%"}
              h="100%"
              alignItems="center"
              justifyContent="center"
              bg={zubeColors.adminGray}
            >
              {children}
            </Box>
          </HStack>
        )
      ) : (
        children
      )}
      {showFooter && width > 1200 ? <Footer /> : null}
    </VStack>
  );
};

PageContainer.defaultProps = {
  spacing: 0,
  w: "100%",
  h: "100%",
};
