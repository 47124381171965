import { type User } from "firebase/auth";
import { isNil } from "lodash/fp";
import { type IFirebaseSerializedUser } from "services/resources/firebase/auth/types.d";
import secureLocalStorage from "react-secure-storage";
import { STORAGE_SESSION_KEY } from "helpers/constants/session-constants";

export const storeSession = (user: User): void => {
  try {
    const strigifiedJsonValue = JSON.stringify(user.toJSON());
    secureLocalStorage.setItem(STORAGE_SESSION_KEY, strigifiedJsonValue);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("Error-storeSession: ", e);
  }
};

export const restoreSession = (): IFirebaseSerializedUser => {
  let firebaseSerializedUser = {} as IFirebaseSerializedUser;
  try {
    const unparsedJson = secureLocalStorage.getItem(STORAGE_SESSION_KEY);
    if (!isNil(unparsedJson)) {
      firebaseSerializedUser = JSON.parse(
        unparsedJson as string
      ) as IFirebaseSerializedUser;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("Error-restoreSession: ", e);
  }
  return firebaseSerializedUser;
};

export const deleteSession = (): void => {
  try {
    secureLocalStorage.removeItem(STORAGE_SESSION_KEY);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("Error-storeSession: ", e);
  }
};
