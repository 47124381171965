import { firebaseInstance } from "services/resources/firebase";
import { type IAxiosResponse } from "services/types.d";

import { type IUser, type UserPayloadType } from "./types.d";

const USERS_API_URL = "/usersAPIv2";

export const userResources = {
  create: (
    user: UserPayloadType,
    token: string
  ): Promise<IAxiosResponse<IUser>> =>
    firebaseInstance.post(
      `${USERS_API_URL}`,
      { user },
      {
        headers: { Authorization: token },
      }
    ),

  enableDriver: (
    userId: string,
    token: string
  ): Promise<IAxiosResponse<string>> =>
    firebaseInstance.patch(
      `${USERS_API_URL}/enable-driver`,
      {},
      {
        headers: { Authorization: token },
        params: { userId },
      }
    ),

  getByEmail: (email: string, token: string): Promise<IAxiosResponse<IUser>> =>
    firebaseInstance.get(`${USERS_API_URL}/by-email`, {
      headers: { Authorization: token },
      params: { email },
    }),

  getById: (id: string, token: string): Promise<IAxiosResponse<IUser>> =>
    firebaseInstance.get(`${USERS_API_URL}/by-id`, {
      headers: { Authorization: token },
      params: { id },
    }),

  getAll: (token: string): Promise<IAxiosResponse<IUser[]>> =>
    firebaseInstance.get(`${USERS_API_URL}/`, {
      headers: { Authorization: token },
    }),

  getAllDrivers: (token: string): Promise<IAxiosResponse<IUser[]>> =>
    firebaseInstance.get(`${USERS_API_URL}/drivers`, {
      headers: { Authorization: token },
    }),

  queryByPage: (
    page: string,
    token: string
  ): Promise<IAxiosResponse<IUser[]>> =>
    firebaseInstance.get(`${USERS_API_URL}/query`, {
      headers: { Authorization: token },
      params: { page },
    }),

  getByMobilePhoneNumber: (
    mobilePhoneNumber: string,
    token: string
  ): Promise<IAxiosResponse<IUser>> =>
    firebaseInstance.get(`${USERS_API_URL}/by-mobile-phone-number`, {
      headers: { Authorization: token },
      params: { mobilePhoneNumber },
    }),

  patchAvailableStatus: (
    newStatus: boolean,
    userId: string,
    token: string
  ): Promise<IAxiosResponse<undefined>> =>
    firebaseInstance.patch(
      `${USERS_API_URL}/available-status`,
      { status: newStatus },
      {
        headers: { Authorization: token },
        params: { id: userId },
      }
    ),

  patchExpoDeviceId: (
    expoDeviceId: string,
    userId: string,
    token: string
  ): Promise<IAxiosResponse<undefined>> =>
    firebaseInstance.patch(
      `${USERS_API_URL}/expo-device-id`,
      { expoDeviceId },
      {
        headers: { Authorization: token },
        params: { id: userId },
      }
    ),

  patchLocation: (
    userId: string,
    location: string,
    token: string
  ): Promise<IAxiosResponse<undefined>> =>
    firebaseInstance.patch(
      `${USERS_API_URL}/location`,
      { location },
      { headers: { Authorization: token }, params: { id: userId } }
    ),

  patchScore: (
    targetUserId: string,
    score: number,
    token: string
  ): Promise<IAxiosResponse<undefined>> =>
    firebaseInstance.patch(
      `${USERS_API_URL}/score`,
      { score },
      {
        headers: { Authorization: token },
        params: { id: targetUserId },
      }
    ),

  putProfilePicture: (
    pictureURL: string,
    userId: string,
    token: string
  ): Promise<void> =>
    firebaseInstance.patch(
      `${USERS_API_URL}/profile-picture`,
      {},
      {
        headers: { authorization: token },
        params: { id: userId, picture: pictureURL },
      }
    ),
};
