import React from "react";

import { InputField } from "../InputField";
import { IInputFieldProps } from "../types.d";

export const PasswordField: React.FC<IInputFieldProps> = (props) => {
  const {
    label = "Contraseña",
    error = "",
    dataType = "password",
    secureText = true,
    onChangeText,
    ...rest
  } = props;
  return (
    <InputField
      type={dataType}
      error={error}
      secureText={secureText}
      label={label}
      onChangeText={onChangeText}
      {...rest}
    />
  );
};
