import { Image, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "components/elements";
import { LoginForm } from "components/forms/LoginForm/LoginForm";
import { FirebaseError } from "firebase/app";
import { handleFirebaseError } from "helpers/error-helpers/auth-error-helpers";
import { isNil } from "lodash/fp";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setUserData } from "redux-service/slices";
import { authResources } from "services/resources/firebase/auth";
import { type IBaseAuthentication } from "services/resources/firebase/auth/types.d";
import { userResources } from "services/resources/firebase/user";
import { ZUBE_LOGO_URL } from "helpers/constants/url-constants";
import { useToast } from "@chakra-ui/react";
import { storeSession } from "helpers/session-helpers/session-storage";
import { useWindowDimensions } from "hooks/layout/dimension-hooks";

export const Login: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);

  const handleLogIn = async (payload: IBaseAuthentication): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      // Log in in Firebase
      const { user } = await authResources.logInWithEmailAndPassword(payload);
      const token = await user.getIdToken();
      // Get the user from the database
      const { data } = await userResources.getByEmail(payload.email, token);
      if (!isNil(data)) {
        if (!isNil(data.isAdmin) && data.isAdmin) {
          storeSession(user);
          // Store the state in Redux
          dispatch(setUserData({ ...data, logged: true, token }));
          setLoadingModalVisible(false);
          // Navigate to home
          navigate("/dashboard");
        } else {
          throw new Error(
            "RM: This user is not allowed to use this application"
          );
        }
      }
    } catch (e) {
      setLoadingModalVisible(false);
      // eslint-disable-next-line no-console
      console.log("Error-handleLogIn: ", JSON.stringify(e));
      // If it is a FirebaseError instance, handle it accordingly
      if (e instanceof FirebaseError) {
        toast({
          title: "Error de autenticación",
          description: handleFirebaseError(e.code),
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      } else if (e instanceof Error && e.message.includes("RM")) {
        toast({
          title: "Error de autenticación",
          description: "Este usuario no puede usar esta aplicación",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      } else {
        toast({
          title: "Error de autenticación",
          description: "Este usuario no puede usar esta aplicación",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };

  return (
    <PageContainer
      showNavbar={false}
      bg="#e6edf7"
      justifyContent="center"
      useLeftPanel={false}
      loadingModalVisible={loadingModalVisible}
    >
      <VStack
        alignItems="center"
        h="100%"
        bg="white"
        borderRadius={5}
        p={7}
        w={width < 1200 ? "95%" : "35%"}
      >
        <Image src={ZUBE_LOGO_URL} my={9} />
        <LoginForm handleOnSubmit={handleLogIn} />
      </VStack>
    </PageContainer>
  );
};
