import { HStack, StackProps, Text, VStack } from "@chakra-ui/react";
import { useWindowDimensions } from "hooks/layout/dimension-hooks";

export const Footer: React.FC<StackProps> = (props): JSX.Element => {
  const { ...rest } = props;
  const { width } = useWindowDimensions();

  return (
    <VStack {...rest}>
      <HStack
        justifyContent="center"
        minH="60px"
        spacing={width > 1200 ? 8 : 350}
        w="90%"
      >
        <Text fontSize={16}>Derechos reservados © Zube</Text>
      </HStack>
    </VStack>
  );
};

Footer.defaultProps = {
  w: "100%",
  bg: "white",
  spacing: 0,
  position: "absolute",
  bottom: 0,
};
