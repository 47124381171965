import { Button, Input, StackProps, Text, VStack } from "@chakra-ui/react";
import { IFare } from "services/resources/firebase/fare/types.d";
import { FareItem } from "./FareItem";
import { DashboardPanel } from "components/elements";
import { isEmpty } from "lodash/fp";

interface IFareProps extends StackProps {
  fare: IFare;
}

export const Fare: React.FC<IFareProps> = (props) => {
  const { fare, ...rest } = props;

  return (
    <DashboardPanel mb={5} {...rest}>
      {!isEmpty(fare) ? (
        <VStack alignItems="flex-start" w="100%" spacing={3}>
          <FareItem
            header="Tarifa base *"
            description="Tarifa de donde se empieza a cobrar."
            input={<Input isDisabled value={fare.baseFare} />}
          />
          <FareItem
            header="Tarifa mínima *"
            description="El costo mínimo del viaje."
            input={<Input isDisabled value={fare.minimumFare} />}
          />
          <FareItem
            header="Tarifa de solicitud *"
            description="Se cobra por el uso de la aplicación."
            input={<Input isDisabled value={fare.appUsageFare} />}
          />
          <FareItem
            header="Tarifa de cancelación *"
            description="En caso de cancelar el viaje se le queda guardado en el historial al pasajero."
            input={<Input isDisabled value={fare.cancellationFare} />}
          />
          <FareItem
            header="Tarifa por KM foraneo *"
            description="Costo del kilometro cuando la tarifa es foránea."
            input={<Input isDisabled value={fare.foreignKilometerFare} />}
          />
          <FareItem
            header="Rango de distancia *"
            description="Determina el número de METROS para cobrar la tarifa por distancia."
            input={<Input isDisabled value={fare.rangePerDistance} />}
          />
          <FareItem
            header="Tipo de tarifa *"
            description="Determina la forma en que se calcula la tarifa."
            input={<Input isDisabled value={fare.fareType} />}
          />
          <FareItem
            header="Tarifa por rango de distancia *"
            description="Costo por cada rango de distancia."
            input={<Input isDisabled value={fare.rangePerDistance} />}
          />
          <FareItem
            header="Tarifa por minuto *"
            description="Costo por cada minuto registrado."
            input={<Input isDisabled value={fare.farePerMinute} />}
          />
          <FareItem
            header="Rango mínimo de busqueda de conductores *"
            description="Determina el rango mínimo para empezar buscar conductores cerca del punto de partida."
            input={
              <Input
                isDisabled
                value={fare.minimumRangeForSendingNotifications}
              />
            }
          />
          <FareItem
            header="Rango máximo de busqueda de conductores *"
            description="Determina el rango máximo en el que se deben buscar conductores alrededor del punto de partida."
            input={
              <Input
                isDisabled
                value={fare.maximumRangeForSendingNotifications}
              />
            }
          />
          <FareItem
            header="Conductores a notificar *"
            description="Determina a cuantos conductores como mínimo se debe buscar para notificar de un nuevo viaje dentro de cada rango."
            input={
              <Input isDisabled value={fare.amountOfDriversToBeNotified} />
            }
          />
          <FareItem
            header="Intervalo de busqueda de conductores *"
            description="Determina cuantos KM se aumenta al siguiente rango de busqueda."
            input={<Input isDisabled value={fare.searchDriversRange} />}
          />
          <FareItem
            header="Segundos de Intervalo de busqueda de conductores *"
            description="Determina cuantos segundos debe esperar la app antes de volver a notificar a los conductores del nuevo viaje."
            input={<Input isDisabled value={fare.newSearchTime} />}
          />
          <Button
            isDisabled
            bg="green"
            color="white"
            alignSelf="flex-end"
            rounded="full"
          >
            Guardar
          </Button>
        </VStack>
      ) : (
        <Text>No se encontraron tarifas.</Text>
      )}
    </DashboardPanel>
  );
};
