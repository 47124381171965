import { DashboardPanel, ProgressBadge } from "components/elements";

interface IJourneysStatsProps {
  cancelled: number;
  cancelledBecauseOfLongWait: number;
  finished: number;
  allJourneysCount: number;
}

export const JourneysStats: React.FC<IJourneysStatsProps> = (
  props
): JSX.Element => {
  const { cancelled, cancelledBecauseOfLongWait, finished, allJourneysCount } =
    props;

  return (
    <DashboardPanel alignItems="flex-start" label="Viajes">
      <ProgressBadge
        total={allJourneysCount}
        sum={cancelled}
        sumLabel="Viajes cancelados"
        w="100%"
      />
      <ProgressBadge
        total={allJourneysCount}
        sum={cancelledBecauseOfLongWait}
        sumLabel="Viajes cancelados por tiempo"
        w="100%"
      />
      <ProgressBadge
        total={allJourneysCount}
        sum={finished}
        sumLabel="Viajes terminados"
        w="100%"
      />
    </DashboardPanel>
  );
};
