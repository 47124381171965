import { HStack, Image, StackProps, Text, VStack } from "@chakra-ui/react";
import { USER_FALLBACK_URL } from "helpers/constants/url-constants";
import { isNil } from "lodash/fp";
import { IUser } from "services/resources/firebase/user/types.d";
import { zubeColors } from "styles/colors";
import { DisplayType } from "../types.d";
import { IoIosArrowDown } from "react-icons/io";

interface IUserCardProps extends StackProps {
  user: IUser;
  showAdminRole?: boolean;
  displayType?: DisplayType;
  showRightIcon?: boolean;
  isHovered?: boolean;
}

export const UserCard: React.FC<IUserCardProps> = (props): JSX.Element => {
  const {
    user,
    showAdminRole = false,
    displayType = "full",
    showRightIcon = false,
    isHovered = false,
    ...rest
  } = props;

  return (
    <HStack {...rest}>
      {displayType === "full" ? (
        <HStack spacing={5} w="100%" h="100%">
          <Image
            src={!isNil(user.img) ? user.img : USER_FALLBACK_URL}
            h="40px"
          />
          {showAdminRole ? (
            <VStack alignItems="flex-start" h="100%">
              <Text color="black">{user.fullName}</Text>
              <Text
                color={!isHovered ? "black" : zubeColors.adminRed}
                fontSize={10}
              >
                Administrador
              </Text>
            </VStack>
          ) : (
            <Text>{user.fullName}</Text>
          )}
          {showRightIcon ? (
            <IoIosArrowDown
              style={{ position: "absolute", right: -10 }}
              color={
                !isHovered ? zubeColors.zubeGray.dark : zubeColors.adminRed
              }
            />
          ) : null}
        </HStack>
      ) : (
        <Image src={!isNil(user.img) ? user.img : USER_FALLBACK_URL} h="50px" />
      )}
    </HStack>
  );
};

UserCard.defaultProps = {
  justifyContent: "center",
  alignItems: "center",
};
