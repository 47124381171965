import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { Router } from "routing/router";
import { reduxStore } from "redux-service/store";
import { Provider } from "react-redux";

export const App: React.FC = (): JSX.Element => {
  return (
    <ChakraProvider>
      <Provider store={reduxStore}>
        <Router />
      </Provider>
    </ChakraProvider>
  );
};
