import { type IFirebaseResource } from "services/resources/firebase/types.d";

export enum UnitStatusEnum {
  ACTIVE = "active",
  PENDING = "pending",
  CANCELLED = "cancelled",
}

export interface IUnit extends IFirebaseResource {
  /**
   * The brand of the vehicle.
   */
  brand: string;
  /**
   * The model of the vehicle.
   */
  model: string;
  /**
   * The plates of the vehicle.
   */
  plates: string;
  /**
   * The year of the vehicle.
   */
  year: number;
  /**
   * Image of the vehicle.
   */
  image: string;
  /**
   * The id of the driver the vehicle belongs to.
   */
  driver: string;
  /**
   * Status of the subscription associated with this vehicle.
   */
  status: UnitStatusEnum;
}

export type UnitPayloadType = Omit<IUnit, "id" | "created" | "updated">;
