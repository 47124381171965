export const zubeColors = {
  locationBlue: "#5583BE",
  zubeGray: {
    dark: "#aaaaaa",
    light: "#d7d7da",
  },
  zubePurple: {
    light: "#8c52ff",
    dark: "#6b40c2",
    lighter: "#c4a6ff",
  },
  zubeGreen: "#7ED957",
  adminRed: "#b5291f",
  adminGray: "#e6edf7",
};
