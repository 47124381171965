import {
  Login,
  ResetPassword,
  Home,
  Users,
  Drivers,
  Fares,
  Requests,
  ReviewRequest,
} from "../pages";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useSession } from "hooks/session/session-hooks";
import { LoadingModal } from "components/elements";

export const Router: React.FC = (): JSX.Element => {
  const { logged, sessionResolved, sessionExists } = useSession();

  const shouldLogIn =
    !logged &&
    !window.location.pathname.includes("login") &&
    sessionResolved &&
    !sessionExists;

  const shouldGoToDashboard =
    !window.location.pathname.includes("dashboard") &&
    logged &&
    sessionResolved &&
    sessionExists;

  useEffect(() => {
    if (shouldLogIn) {
      // Redirect to the login page
      window.location.href = "/admin/login";
    } else if (shouldGoToDashboard) {
      window.location.href = "/admin/dashboard";
    }
  }, [
    logged,
    sessionResolved,
    sessionExists,
    shouldLogIn,
    shouldGoToDashboard,
  ]);

  return (
    <>
      <LoadingModal
        isOpen={!sessionResolved}
        onClose={() => {}}
        children={<></>}
      />
      <BrowserRouter basename="admin">
        <Routes>
          {!logged ? (
            <>
              <Route element={<Login />} path="/login" />
              <Route element={<ResetPassword />} path="/password-reset" />
            </>
          ) : null}
          {logged ? (
            <>
              <Route element={<Home />} path="/dashboard" />
              <Route element={<Users />} path="/users" />
              <Route element={<Drivers />} path="/drivers" />
              <Route element={<Fares />} path="/fares" />
              <Route element={<Requests />} path="/requests" />
              <Route
                element={<ReviewRequest />}
                path="/review-request/:requestId"
              />
            </>
          ) : null}
        </Routes>
      </BrowserRouter>
    </>
  );
};
